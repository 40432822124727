// enclosures-terraces
import img1 from "../../img/enclosures-terraces/img1.jpeg";
import img1_1 from "../../img/enclosures-terraces/img1_1.jpg";
import img1_2 from "../../img/enclosures-terraces/img1_2.jpg";
import img1_3 from "../../img/enclosures-terraces/img1_3.jpg";
import img1_4 from "../../img/enclosures-terraces/img1_4.jpg";
import img1_5 from "../../img/enclosures-terraces/img1_5.jpg";
import IMG1b from "../../img/enclosures-terraces/IMG1b.jpg";
import img2 from "../../img/enclosures-terraces/img2.jpeg";
import img2_5 from "../../img/enclosures-terraces/img2_5.jpg";
import img3 from "../../img/enclosures-terraces/img3.jpeg";
import img4 from "../../img/enclosures-terraces/img4.jpeg";
import img5 from "../../img/enclosures-terraces/img5.jpeg";
import img6 from "../../img/enclosures-terraces/img6.jpg";
import img6_1 from "../../img/enclosures-terraces/img6_1.jpg";
import img6_2 from "../../img/enclosures-terraces/img6_2.jpg";
import img6_3 from "../../img/enclosures-terraces/img6_3.jpg";
import img6_4 from "../../img/enclosures-terraces/img6_4.jpg";
import img7 from "../../img/enclosures-terraces/img7.jpg";
import img8 from "../../img/enclosures-terraces/img8.jpg";
import img9 from "../../img/enclosures-terraces/img9.jpeg";
import img10 from "../../img/enclosures-terraces/img10.jpeg";
import img10_5 from "../../img/enclosures-terraces/img10_5.jpeg";
import img10_7 from "../../img/enclosures-terraces/img10_7.jpg";
import img11 from "../../img/enclosures-terraces/img11.jpg";
import img11_1 from "../../img/enclosures-terraces/img11_1.jpg";
import img11_2 from "../../img/enclosures-terraces/img11_2.jpg";
import img11_3 from "../../img/enclosures-terraces/img11_3.jpg";
import img11_4 from "../../img/enclosures-terraces/img11_4.jpg";
import img11_5 from "../../img/enclosures-terraces/img11_5.jpg";
import img11_6 from "../../img/enclosures-terraces/img11_6.jpg";
import img12 from "../../img/enclosures-terraces/img12.jpg";

export const enclousuresTerraces = [
  img1,
  img1_1,
  img1_2,
  img1_3,
  img1_4,
  img1_5,
  IMG1b,
  img2,
  img2_5,
  img3,
  img4,
  img5,
  img6_1,
  img6_2,
  img6_3,
  img6_4,
  img6,
  img7,
  img8,
  img9,
  img10,
  img10_5,
  img10_7,
  img11,
  img11_1,
  img11_2,
  img11_3,
  img11_4,
  img11_5,
  img11_6,
  img12,
];
