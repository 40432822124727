import React from 'react';
import { enclousuresTerraces } from './ImageStorage/enclousuresTerraces';
import { roofsBalcony } from './ImageStorage/roofsBalcony';
import { others } from './ImageStorage/others';

export const NavigationElements = [
  {
    name: "Zabudowy",
    link: "",
    submenu: [
      {
        name: "tarasów",
        link: "/enclosures/terraces",
      }, {
        name: "balkonów",
        link: "/enclosures/balcony",
      }]
  },
  {
    name: ' Daszki i zadaszenia',
    link: "",
    submenu: [
      {
        name: "tarasy",
        link: "/roofs/terraces",
      },
      {
        name: "balkony",
        link: "/roofs/balcony",
      },
      {
        name: 'wejścia, garaże i ciągi komunikacyjne',
        link: "/roofs",
      },
    ]
  },
  {
    name: "Inne",
    link: "/others",
  },
  {
    name: "Kontakt",
    link: "/contact",
  }];

export const footerLinks = [
  {
    link: '/enclosures/terraces',
    name: 'Zabudowy tarasów',
  },
  {
    link: '/enclosures/balcony',
    name: 'Zabudowy balkonów',
  },
  {
    link: '/roofs/terraces',
    name: ' Daszki i zadaszenia tarasów',
  },
  {
    link: '/roofs/balcony',
    name: ' Daszki i zadaszenia balkonów',
  },
  {
    link: '/roofs',
    name: 'Daszki i zadaszenia wejść, garaży i ciągów komunikacyjnych',
  },
  {
    link: "/others",
    name: "Inne",
  },
  {
    link: "/contact",
    name: "Kontakt",
  },
]

export const howWeDoIt = [
  {
    icon: 'square_foot',
    text: 'Doświadczenie',
    subText: 'ponad 30 lat',
    color: '#37474F',
  },
  {
    icon: 'construction',
    text: 'Profesjonalizm',
    subText: 'tysiące zadowolonych klientów',
    color: '#37474F',
  },
  {
    icon: 'roofing',
    text: 'Materiały',
    subText: 'najwyższej jakości',
    color: '#37474F',
  },
];

export const materials = [
  {
    icon: 'security',
    text: 'Kompetencje',
    subText: 'wykonujemy realizacje, którym nie podoła konkurencja',
    color: '#37474F',
  },
  {
    icon: 'star',
    text: 'Indywidualne podejście',
    subText: <>służymy wiedzą i&nbsp;doświadczeniem aby dobrać optymalne rozwiązanie</>,
    color: '#37474F',
  },
  {
    icon: 'verified',
    text: 'Kompleksowość',
    subText: <>nie musisz się o&nbsp;nic martwić - zajmiemy się wszystkimi aspektami prac</>,
    color: '#37474F',
  },
];

export const services = [
  {
    img: enclousuresTerraces[14],
    article: 'Wykonujemy zabudowy tarasów - także tych nietypowych, ogrody zimowe, zabudowę balkonów, wejść do budynków czy pomieszczeń technicznych. Stosujemy system aluminiowych okien przesuwnych. Rozwiązania dobieramy do architektury budynku.',
    header: 'Tarasy',
    subHeader: 'Co powinienem wiedzieć?',
    links: [{
      text: 'Zabudowa tarasów',
      link: '/enclosures/terraces',
    },
    {
      text: 'Zabudowa balkonów',
      link: '/enclosures/balcony',
    }],
  },
  {
    img: roofsBalcony[2],
    header: <>Daszki i&nbsp;zadaszenia</>,
    article: <>Zajmujemy się zadaszeniami tarasów, balkonów, wejść do budynków, podjazdów garażowych i&nbsp;innych miejsc. Realizujemy zadaszenia ciągów komunikacyjnych. Pokrycie dachu stanowi poliwęglan lity lub komorowy w&nbsp;trzech kolorach do wyboru, w&nbsp;różnych dostępnych grubościach. Rozwiązania dobieramy do architektury budynku.</>,
    subHeader: 'Co powinienem wiedzieć?',
    links: [
      {
        text: 'Tarasy',
        link: '/roofs/terraces',
      },
      {
        text: 'Balkony',
        link: '/roofs/balcony',
      },
      {
        text: 'Wejścia, garaże i ciągi komunikacyjne',
        link: '/roofs',
      }
    ],
  },
  {
    img: others[4],
    article: <>Przyjmujemy do realizacji nietypowe konstrukcje stalowe oraz inne roboty ślusarskie - zaczynając od składanego dachu, na halach kończąc. Jeżeli się zastanawiasz, czy Twój pomysł można zrealizować - skontaktuj się z&nbsp;nami.</>,
    header: 'Inne prace',
    subHeader: 'Co powinienem wiedzieć?',
    links: [{
      text: 'Inne prace',
      link: '/others',
    }],
  }
];