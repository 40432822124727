
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { ButtonShadowStyle } from './ShadowBox/ShadowBox';

const ButtonBaseStyles = css`
  height: 36px;
  border: none;
  color: #fff;
  border-radius: 4px;
  transition: box-shadow 275ms;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  ${ButtonShadowStyle}
`

export const Button = styled.button<{ background?: string }>`
  ${ButtonBaseStyles}
  padding: 0 20px;
  background: ${({ background }) => background || '#64B5F6'};
`;

export const ButtonLink = styled(Link)`
  ${ButtonBaseStyles}
  display: flex;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  background-color: #64B5F6;
  text-decoration: none;
`;