
import React from 'react';
import styled, { css, CSSProp } from 'styled-components';

const StyledContainer = styled.div<{ paddingVert?: boolean; paddingHori?: boolean; }>`
    margin: 0 auto;
    max-width: 1080px;
    ${({ paddingVert }): CSSProp => (
        paddingVert
        && css`
            padding-left: 25px;
            padding-right: 25px;
        `
    ) || ''}
    ${({ paddingHori }): CSSProp => (
        paddingHori
        && css`
            padding-top: 19px;
            padding-bottom: 19px;
        `
    ) || ''}
`;

interface Props {
    children: JSX.Element | JSX.Element[] | string;
    as?: "div" | 'article' | 'section';
    paddingVert?: boolean;
    paddingHori?: boolean;
}

export const Container = ({
    children,
    as = 'div',
    paddingVert,
    paddingHori,
    ...props
}: Props) => (
        <StyledContainer
            paddingVert={paddingVert}
            paddingHori={paddingHori}
            {...props}
            as={as}
        >
            {children}
        </StyledContainer>
    )