import img1_1 from '../../img/roofs-terraces/img1_1.jpeg';
import img1_2 from '../../img/roofs-terraces/img1_2.jpg';
import img1_3 from '../../img/roofs-terraces/img1_3.jpg';
import img1_4 from '../../img/roofs-terraces/img1_4.jpg';
import img1_5 from '../../img/roofs-terraces/img1_5.jpg';
import img1 from '../../img/roofs-terraces/img1.jpg';
import img2 from '../../img/roofs-terraces/img2.jpg';
import img3 from '../../img/roofs-terraces/img3.jpg';
import img4 from '../../img/roofs-terraces/img4.jpg';
import img5 from '../../img/roofs-terraces/img5.jpg';
import img5_5 from '../../img/roofs-terraces/img5_5.jpg';
import img5_7 from '../../img/roofs-terraces/img5_7.jpg';
import img6 from '../../img/roofs-terraces/img6.jpg';
import img7 from '../../img/roofs-terraces/img7.jpg';
import img7_1 from '../../img/roofs-terraces/img7_1.jpg';
import img7_2 from '../../img/roofs-terraces/img7_2.jpg';
import img7_3 from '../../img/roofs-terraces/img7_3.jpg';
import img7_4 from '../../img/roofs-terraces/img7_4.jpg';
import img7_5 from '../../img/roofs-terraces/img7_5.jpg';
import img8 from '../../img/roofs-terraces/img8.jpg';
import img9 from '../../img/roofs-terraces/img9.jpeg';
import img10 from '../../img/roofs-terraces/img10.jpg';
import img11 from '../../img/roofs-terraces/img11.jpg';
import img11_1 from '../../img/roofs-terraces/img11_1.jpg';
import img11_2 from '../../img/roofs-terraces/img11_2.jpg';
import img12 from '../../img/roofs-terraces/img12.jpg';
import img13 from '../../img/roofs-terraces/img13.jpg';
import img14 from '../../img/roofs-terraces/img14.jpg';
import img15 from '../../img/roofs-terraces/img15.jpg';
import img16 from '../../img/roofs-terraces/img16.jpg';
import img17 from '../../img/roofs-terraces/img17.JPG';
import img18 from '../../img/roofs-terraces/img18.jpeg';
import img18a from '../../img/roofs-terraces/img18a.jpg';
import img20 from '../../img/roofs-terraces/img20.jpg';
import img21 from '../../img/roofs-terraces/img21.jpg';
import img22 from '../../img/roofs-terraces/img22.jpg';
import img22b from '../../img/roofs-terraces/img22b.jpg';
import img22c from '../../img/roofs-terraces/img22c.jpg';
import img22d from '../../img/roofs-terraces/img22d.jpg';
import img22e from '../../img/roofs-terraces/img22e.jpg';
import img22f from '../../img/roofs-terraces/img22f.jpg';
import img23 from '../../img/roofs-terraces/img23.jpg';
import img24 from '../../img/roofs-terraces/img24.jpg';
import img25 from '../../img/roofs-terraces/img25.jpg';

export const roofsTerraces = [
  img1_1,
  img1_2,
  img1_3,
  img1_4,
  img1_5,
  img1,
  img2,
  img3,
  img4,
  img5,
  img5_5,
  img5_7,
  img6,
  img7,
  img7_1,
  img7_2,
  img7_3,
  img7_4,
  img7_5,
  img8,
  img9,
  img10,
  img11,
  img11_1,
  img11_2,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img18a,
  img20,
  img21,
  img22,
  img22b,
  img22c,
  img22d,
  img22e,
  img22f,
  img23,
  img24,
  img25,
]