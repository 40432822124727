import React from 'react';
import { enclousuresTerraces } from '../ImageStorage/enclousuresTerraces';
import { SubPagesBanner } from '../../view/SubPagesBanner/SubPagesBanner';
import { Navbar } from '../../view/Navbar/Navbar';
import { AppRouting } from '../interface';
import { Flex, Section } from './../../view/Flex';
import { Paragraph } from './../../view/Paragraph';
import { Icon } from './../../view/Icon';
import { Process } from './../Home/components/Process';
import { HeaderWithLines } from './../../view/TextForms/HeaderWithLines';
import { Container } from './../../view/Containers/Containers';
import styled, { css } from 'styled-components';
import { media } from './../../view/media/media';

const BaseLink = css`
  font-size: 30px;
  font-weight: bold;
  margin-left: 1rem;
  text-decoration: none;
  color: #333;
  transition: color 275ms;
  
  @media(max-width: ${media.small}){
    font-size: 20px;
    margin-left: 0.5rem;
  }

  &:hover {
    color:#64B5F6;
  }
`;

const MailLink = styled.a`
 ${BaseLink}
  &:hover {
    text-decoration: underline;
  }
`;

const PhoneNumber = styled.a`${BaseLink}`;

const ContactWrapper = styled(Flex)`
@media(max-width: ${media.small}){
  flex-direction: column;
}
`

const ContactLinkWrapper = styled(Flex)`
@media(max-width: ${media.small}){
  justify-content: center;

  i {
    font-size: 30px;
  }
}
`

const ContactHeaderWrapper = styled(Flex)`
    .h3 {
      display: none;
    }

  @media(max-width: ${media.small}) {
    h2 {
      display: none;
    }
    h3 {
      display: initial;
      font-size: 30px;
      text-align: center;
    }
  }

`;

export const ContactPage = (
  { navElements, logo }: { navElements: Navbar.NavElement[]; logo: string }) => {
  return (
    <>
      <Navbar navElements={navElements} logo={logo} />
      <SubPagesBanner
        header="Kontakt"
        text=''
        img={enclousuresTerraces[0]}
      />
      <Section block>
        <Container>
          <ContactHeaderWrapper>
            <HeaderWithLines>
              Jak się z&nbsp;nami skontaktować?
            </HeaderWithLines>
          </ContactHeaderWrapper>
          <ContactWrapper justify="space-around">
            <ContactLinkWrapper justify="flex-start" align="center">
              <Icon fontSize="70px" color="#64B5F6">
                phone
              </Icon>
              &nbsp;
              <Paragraph>
                <PhoneNumber
                  href="tel:+48504107384"
                >
                  +48 504 107 384
                </PhoneNumber>
              </Paragraph>
            </ContactLinkWrapper>
            <ContactLinkWrapper justify="flex-start" align="center">
              <Icon fontSize="70px" color="#64B5F6">
                mail
              </Icon>
              &nbsp;
              <Paragraph>
                <MailLink href="mailTo:biuro@budmag.pl">biuro@budmag.pl</MailLink>
              </Paragraph>
            </ContactLinkWrapper>
          </ContactWrapper>
        </Container>
        <Process />
      </Section>
    </>
  );
}