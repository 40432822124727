import React from 'react';
import { services } from "./DataSheets";
import { enclousuresBalcony } from './ImageStorage/enclosuresBalcony';
import { enclousuresTerraces } from './ImageStorage/enclousuresTerraces';
import { others } from "./ImageStorage/others";
import { roofs } from "./ImageStorage/roofs";
import { roofsBalcony } from './ImageStorage/roofsBalcony';
import { roofsTerraces } from './ImageStorage/roofsTerraces';

export const EnclousuresTerracesData = {
  bannerHeader: 'Tarasy',
  bannerSubHeader: services[0].article,
  mainImage: enclousuresTerraces[0],
  photos: enclousuresTerraces,
}

export const EnclousuresBalconyData = {
  bannerHeader: 'Balkony',
  bannerSubHeader: services[0].article,
  mainImage: enclousuresBalcony[6],
  photos: enclousuresBalcony,
}

export const RoofsTerracesData = {
  bannerHeader: 'Tarasy',
  bannerSubHeader: services[1].article,
  mainImage: roofsTerraces[10],
  photos: roofsTerraces,
}

export const RoofsBalconyData = {
  bannerHeader: 'Balkony',
  bannerSubHeader: services[1].article,
  mainImage: roofsBalcony[2],
  photos: roofsBalcony,
}

export const RoofsData = {
  bannerHeader: <>Wejścia, garaże i&nbsp;ciągi komunikacyjne</>,
  bannerSubHeader: services[1].article,
  mainImage: roofs[28],
  photos: roofs,
}

export const OthersData = {
  bannerHeader: 'Inne',
  bannerSubHeader: services[2].article,
  mainImage: others[3],
  photos: others,
}

export const seeMore = [
  {
    image: EnclousuresTerracesData.mainImage,
    linkDetails: {
      link: services[0].links[0].link,
      name: 'Zabudowa tarasów',
    },
  },
  {
    image: EnclousuresBalconyData.mainImage,
    linkDetails: {
      link: services[0].links[1].link,
      name: 'Zabudowa balkonów',
    },
  },
  {
    image: RoofsTerracesData.mainImage,
    linkDetails: {
      link: services[1].links[0].link,
      name: 'Zadaszenie tarasów',
    },
  },
  {
    image: RoofsBalconyData.mainImage,
    linkDetails: {
      link: services[1].links[1].link,
      name: 'Zadaszenie balkonów',
    },
  },
  {
    image: RoofsData.mainImage,
    linkDetails: {
      link: services[1].links[2].link,
      name: 'Zadaszenia wejść i innych',
    },
  },
  {
    image: OthersData.mainImage,
    linkDetails: {
      link: services[2].links[0].link,
      name: 'Pozostałe prace',
    },
  },
]