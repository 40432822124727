import init from '../../img/roofs/init.jpg';
import img0 from '../../img/roofs/img0.jpg';
import img0_1_entrance from '../../img/roofs/img0_1_entrance.jpg';
import img0_2_entrance from '../../img/roofs/img0_2_entrance.jpg';
import img0_0 from '../../img/roofs/img0_0.jpg';
import img0_1 from '../../img/roofs/img0_1.jpg';
import img0_2 from '../../img/roofs/img0_2.jpg';
import img0_3 from '../../img/roofs/img0_3.jpg';
import img0_4 from '../../img/roofs/img0_4.jpg';
import img0_5 from '../../img/roofs/img0_5.jpg';
import img0a from '../../img/roofs/img0a.jpg';
import img0b from '../../img/roofs/img0b.jpg';
import img0c from '../../img/roofs/img0c.jpg';
import img0d from '../../img/roofs/img0d.jpg';
import img1 from '../../img/roofs/img1.jpg';
import img2 from '../../img/roofs/img2.jpg';
import img3 from '../../img/roofs/img3.jpg';
import img4 from '../../img/roofs/img4.jpeg';
import img4_5 from '../../img/roofs/img4_5.jpeg';
import img4_7 from '../../img/roofs/img4_7.jpg';
import img5 from '../../img/roofs/img5.jpg';
import img6 from '../../img/roofs/img6.jpg';
import img7 from '../../img/roofs/img7.jpg';
import img8 from '../../img/roofs/img8.jpg';
import img8_1 from '../../img/roofs/img8_1.jpg';
import img8_2 from '../../img/roofs/img8_2.jpg';
import img8_3 from '../../img/roofs/img8_3.jpg';
import img9 from '../../img/roofs/img9.jpg';
import img10 from '../../img/roofs/img10.jpg';
import img11 from '../../img/roofs/img11.jpg';
import img12 from '../../img/roofs/img12.jpg';
import img13 from '../../img/roofs/img13.jpg';
import img14 from '../../img/roofs/img14.jpg';
import img15 from '../../img/roofs/img15.jpg';
import img16 from '../../img/roofs/img16.jpg';
import img17 from '../../img/roofs/img17.JPG';
import img18 from '../../img/roofs/img18.jpg';
import img20 from '../../img/roofs/img20.jpg';
import img21 from '../../img/roofs/img21.jpg';
import img22 from '../../img/roofs/img22.jpg';
import img23 from '../../img/roofs/img23.jpg';
import img24 from '../../img/roofs/img24.jpg';
import img25 from '../../img/roofs/img25.jpg';
import img26 from '../../img/roofs/img26.jpg';
import img27 from '../../img/roofs/img27.jpg';
import img28 from '../../img/roofs/img28.jpg';
import img29 from '../../img/roofs/img29.jpg';
import img30 from '../../img/roofs/img30.jpg';
import img31 from '../../img/roofs/img31.jpg';
import img32 from '../../img/roofs/img32.jpg';
import img33 from '../../img/roofs/img33.jpg';
import img34 from '../../img/roofs/img34.jpg';
import img35 from '../../img/roofs/img35.jpg';
import img36 from '../../img/roofs/img36.jpg';
import img37 from '../../img/roofs/img37.jpg';
import img38 from '../../img/roofs/img38.jpg';
import img39 from '../../img/roofs/img39.jpg';
import img40 from '../../img/roofs/img40.jpg';
import img41 from '../../img/roofs/img41.jpg';
import img42 from '../../img/roofs/img42.jpg';
import img43 from '../../img/roofs/img43.jpg';
import img44 from '../../img/roofs/img44.jpg';
import img45 from '../../img/roofs/img45.jpg';
import img46 from '../../img/roofs/img46.jpg';
import img47 from '../../img/roofs/img47.jpg';
import img48 from '../../img/roofs/img48.jpg';
import img49 from '../../img/roofs/img49.jpg';
import img50 from '../../img/roofs/img50.jpg';
import img51 from '../../img/roofs/img51.jpg';
import img52 from '../../img/roofs/img52.jpg';
import img53 from '../../img/roofs/img53.jpg';

export const roofs = [
  img0_1_entrance,
  img0_2_entrance,
  img0_0,
  img0_1,
  img0_2,
  img0_3,
  img0_4,
  img0_5,
  init,
  img0,
  img0a,
  img0b,
  img0c,
  img0d,
  img1,
  img2,
  img3,
  img4,
  img4_5,
  img4_7,
  img5,
  img6,
  img7,
  img8,
  img8_1,
  img8_2,
  img8_3,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  img43,
  img44,
  img45,
  img46,
  img47,
  img48,
  img49,
  img50,
  img51,
  img52,
  img53,
]