import React from 'react';
import styled, { css } from 'styled-components';
import { LargeShadowStyle } from './ShadowBox/ShadowBox';

const BannerBackground = styled.section<{ img: string, full?: boolean; short?: boolean; shadow?: boolean }>`
${LargeShadowStyle}
${({ shadow }) => (
    shadow &&
    css`
      background: linear-gradient(90deg, rgba(0,0,0,0.7) 33%, rgba(0,0,0,0.3) 66%, rgba(0,0,0, 0) 99%),
        url(${({ img }: { img: string }) => img || ''}),
        #bdbdbd;
    `)
    || css`
      background: linear-gradient(90deg, rgba(0,0,0,0.7) 50%, rgba(0,0,0, 0.7) 100%),
        url(${({ img }: { img: string }) => img || ''}),
        #bdbdbd;
    `}

  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: baseline;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-sizing: border-box;
  padding-top: 45px;


  ${({ full }) => (full
    && css`
      min-height: 100vh;
    `)
    || css`
      height: auto;
    `}

    ${({ short }) => (short
    && css`min-height: 60vh;`
    || '')}
`;

export const WelcomeSectionText = styled.article`
    padding: 0 25px;
    max-width: 50%;
    min-width: 500px;
    text-align: left;
    margin-bottom: 50px;

    @media(max-width: 860px) {
      min-width: 100%;
      box-sizing: border-box;
    }
`;

interface Props {
  children?: React.ReactNode;
  img: string;
  shadow?: boolean;
  full?: boolean;
  short?: boolean;
}

export const Banner = ({ img, shadow, full, short, children }: Props): JSX.Element => (
  <BannerBackground
    shadow={shadow}
    img={img}
    full={full}
    short={short}
  >
    {children}
  </BannerBackground>
) 