// enclosures_balcony
import img0_1 from "../../img/enclosures-balcony/img0_1.jpg";
import img0_2 from "../../img/enclosures-balcony/img0_2.jpg";
import img3 from "../../img/enclosures-balcony/img3.jpg";
import img1 from "../../img/enclosures-balcony/img1.jpeg";
import img2_5 from "../../img/enclosures-balcony/img2_5.jpg";
import img2 from "../../img/enclosures-balcony/img2.jpeg";
import img3_1 from "../../img/enclosures-balcony/img3_1.jpg";
import img3_2 from "../../img/enclosures-balcony/img3_2.jpg";
import img3_3 from "../../img/enclosures-balcony/img3_3.jpg";
import img3_4 from "../../img/enclosures-balcony/img3_4.jpg";
import img3_5 from "../../img/enclosures-balcony/img3_5.jpg";
import img4 from "../../img/enclosures-balcony/img4.jpg";
import img5 from "../../img/enclosures-balcony/img5.jpg";
import img6 from "../../img/enclosures-balcony/img6.jpg";
import img7 from "../../img/enclosures-balcony/img7.jpg";
import img8 from "../../img/enclosures-balcony/img8.jpg";
import img9 from "../../img/enclosures-balcony/img9.jpg";

export const enclousuresBalcony = [
  img0_1,
  img0_2,
  img1,
  img2,
  img2_5,
  img3,
  img3_1,
  img3_2,
  img3_3,
  img3_4,
  img3_5,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
];
