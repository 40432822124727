import React, { Fragment, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { NavShadow } from './../ShadowBox/ShadowBox';
import { MainElementsProps } from '../../components/interface';
import { Icon } from './../Icon';
import { media } from './../media/media';

const Nav = styled.nav`
    ${NavShadow}
    position: fixed;
    z-index: 1;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    background: rgb(245,245,245);

    @media(max-width: ${media.small}) {
      justify-content: flex-start;
    }
`;

const BaseNavbarButtonView = css<{ active: boolean }>`
    font-size: 14px;
    font-weight: bold;
    font-family: 'Noto Sans', sans-serif;
    margin: 0;
    padding: 0 20px;
    color: ${({ active }) => active ? '#58a5f0' : '#333'};
    line-height: 45px;
    text-decoration: none;
    text-transform: uppercase;
    border-bottom: 2px solid transparent;

    &:hover {
        color: #58a5f0;
        border-bottom: 2px solid;
    }
`

const NavMenuButton = styled.button`
    border: none;
    ${BaseNavbarButtonView}
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
`;

const NavLogo = styled.img`
    max-height: 45px;
    padding-left: 10px;
`;

const NavbarLink = styled(Link) <{ active: boolean }>`
  ${BaseNavbarButtonView}
`;

const NavbarSubMenuWrapper = styled.span`
  position: relative;
`;

const NavbarSubMenuButton = styled.button`
  border: none;
  background: transparent;
  outline: transparent;
  ${BaseNavbarButtonView}

  @media(max-width: ${media.small}) {
    width: 100%;
    text-align: left;
  }
`;

const NavbarSubMenu = styled.ul`
  position: absolute;
  background: #fff;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  padding: 0;
  margin: 0;
  min-width: 190px;

  @media(max-width: ${media.small}) {
    left: 100%;
    top: 0;
  }
`;

const NavbarSubMenuLink = styled(Link)`
  font-size: 14px;
  font-weight: bold;
  font-family: 'Noto Sans', sans-serif;
  color: #333;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 3;
  padding: 0 10px;
  width: 100%;
`;

const NavbarSubMenuItem = styled.li`
  list-style: none;
  display: flex;

  &:hover {
    background-color: #f5f5f5;

    ${NavbarSubMenuLink} {
      color: #58a5f0;
    }
  }
`;

const MenuWrapper = styled.div`
  position: absolute;
  top: 45px;
  background: #fff;
  display: flex;
  flex-direction: column;
`;

const NavbarLinks = styled.div`
    display: flex;
`;

export const Navbar = ({ navElements, logo }: MainElementsProps): JSX.Element => {
  const { location } = useHistory();
  const [isOpen, setIsOpen] = useState<string>('');
  const [smallView, setSmallView] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const navRef = useRef<HTMLBaseElement>(null);

  const onMouseOut = useCallback((target): void => {
    if (navRef && navRef.current && !navRef.current.contains(target)) {
      setIsOpen('');
    }
  }, [navRef]);

  useEffect((): void => {
    window.addEventListener('mouseover', (e) => {
      const { target } = e;
      onMouseOut(target);
    });
  }, []);

  const onResizeWindow = () => {
    if (window.innerWidth <= 860) {
      setSmallView(true);
      window.removeEventListener('resize', onResizeWindow);
    }
  }

  useLayoutEffect((): void => {
    onResizeWindow();
    window.addEventListener('resize', onResizeWindow);
  }, []);

  return (
    <>
      {!navElements
        ? <></>
        : (
          <Nav ref={navRef}>
            {!smallView
              ? (
                <>
                  <Link to="/">
                    <NavLogo alt="Logo" src={logo} />
                  </Link>
                  <NavbarLinks>
                    {navElements
                      .map((menu: Navbar.NavElement): React.ReactNode => (
                        <Fragment key={menu.link + menu.name}>
                          {menu.submenu
                            ? (
                              <NavbarSubMenuWrapper>
                                <NavbarSubMenuButton
                                  active={location.pathname === menu.link}
                                  onMouseOver={(): void => setIsOpen(menu.name)}
                                >
                                  {menu.name}
                                </NavbarSubMenuButton>
                                {isOpen === menu.name && (
                                  <NavbarSubMenu
                                    onMouseOver={(e): void => onMouseOut(e.target)}
                                  >
                                    {menu.submenu.map((sub) =>
                                      <NavbarSubMenuItem key={sub.link}>
                                        <NavbarSubMenuLink to={sub.link}>
                                          {sub.name}
                                        </NavbarSubMenuLink>
                                      </NavbarSubMenuItem>
                                    )}
                                  </NavbarSubMenu>
                                )}
                              </NavbarSubMenuWrapper>
                            )
                            : (
                              <NavbarLink
                                active={location.pathname === menu.link}
                                key={menu.link + menu.name}
                                to={menu.link}
                              >
                                {menu.name}
                              </NavbarLink>
                            )}
                        </Fragment>
                      ))}
                  </NavbarLinks>
                </>
              )
              : (
                <>
                  <NavMenuButton
                    active={false}
                    onClick={(): void => setIsMenuOpen(!isMenuOpen)}
                  >
                    <Icon>
                      menu
                    </Icon>
                  </NavMenuButton>
                  <Link to="/">
                    <NavLogo alt="Logo" src={logo} />
                  </Link>
                  {isMenuOpen && (
                    <MenuWrapper>
                      {navElements
                        .map((menu: Navbar.NavElement): React.ReactNode => (
                          <Fragment key={menu.link + menu.name}>
                            {menu.submenu
                              ? (
                                <NavbarSubMenuWrapper>
                                  <NavbarSubMenuButton
                                    active={location.pathname === menu.link}
                                    onClick={(): void => setIsOpen(menu.name)}
                                  >
                                    {menu.name}
                                  </NavbarSubMenuButton>
                                  {isOpen === menu.name && (
                                    <NavbarSubMenu
                                      onMouseOver={(e): void => onMouseOut(e.target)}
                                    >
                                      {menu.submenu.map((sub) =>
                                        <NavbarSubMenuItem key={sub.link}>
                                          <NavbarSubMenuLink to={sub.link}>
                                            {sub.name}
                                          </NavbarSubMenuLink>
                                        </NavbarSubMenuItem>
                                      )}
                                    </NavbarSubMenu>
                                  )}
                                </NavbarSubMenuWrapper>
                              )
                              : (
                                <NavbarLink
                                  active={location.pathname === menu.link}
                                  key={menu.link + menu.name}
                                  onMouseOver={(): void => setIsOpen('')}
                                  to={menu.link}
                                >
                                  {menu.name}
                                </NavbarLink>
                              )}
                          </Fragment>
                        ))}
                    </MenuWrapper>
                  )}
                </>
              )}
          </Nav>
        )}
    </>
  )
}