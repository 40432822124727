import React from 'react';
import styled from 'styled-components';
import { Banner } from '../Banner';
import { WelcomeSectionText } from './../Banner';
import { H1 } from '../Header';
import { Paragraph } from './../Paragraph';

const BannerHeade = styled(H1)`
  color: #fff;
  font-size: 35px;
`;

const BannerText = styled(Paragraph)`
  color: #fff;
`;


export const SubPagesBanner = ({ img, header, text }: SubPageBanner.SubPageElements): JSX.Element => (
  <Banner shadow short img={img}>
    <WelcomeSectionText>
      <BannerHeade>
        {header}
      </BannerHeade>
      <BannerText>
        <>
          {text}
        </>
      </BannerText>
    </WelcomeSectionText>
  </Banner>
);