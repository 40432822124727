import React from 'react';
import styled from 'styled-components';

interface Props extends React.HtmlHTMLAttributes<HTMLElement> {
    children: string;
    color?: string;
    fontSize?: string;
    suffix?: 'outlined' | 'two-tone' | 'round' | 'sharp';
}

const MaterialIcon = styled.i`
    color: ${({ color }) => color || '#333'};
    font-size: ${({ fontSize }: { fontSize?: string }) => fontSize || '24px'};
`;

export const Icon = ({ children, color, fontSize, suffix, ...props }: Props) => (
    <MaterialIcon
        {...props}
        fontSize={fontSize}
        color={color}
        className={`${props.className} material-icons${suffix ? `-${suffix}` : ''}`}
    >
        {children}
    </MaterialIcon>
)