import React from 'react';
import { WelcomeSection } from './components/WelcomeSection';
import { Navbar } from '../../view/Navbar/Navbar'
import { ServicesPage } from './components/ServicesPage';
import { ContactSection } from '../../view/ContactSection';
import { Process } from './components/Process';
import { Separator } from '../../view/Separators';
import { MainElementsProps } from '../interface';
import { InfoBanner } from './components/InfoBanner';

export const Home = ({ navElements, logo }: MainElementsProps): JSX.Element => (
  <>
    <Navbar navElements={navElements} logo={logo} />
    <WelcomeSection />
    <ContactSection />
    <Separator />
    <InfoBanner />
    <Separator />
    <ServicesPage />
    <Separator />
    <Process />
  </>
)