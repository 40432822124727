import React from 'react';
import { Container } from './../../../view/Containers/Containers';
import { Flex } from './../../../view/Flex';
import { TeaserBoxes } from '../../../view/TeaserBoxes/TeaserBoxes';
import styled, { css, CSSProp } from 'styled-components';
import { H3, H4 } from './../../../view/Header';
import { Paragraph } from './../../../view/Paragraph';
import { HeaderWithLines } from './../../../view/TextForms/HeaderWithLines';
import { Separator } from './../../../view/Separators';
import { ButtonLink } from './../../../view/Buttons';
import { services } from '../../DataSheets';
import { MediumShadowStyle } from './../../../view/ShadowBox/ShadowBox';
import { media } from './../../../view/media/media';

const ServicesContainers = css`
    width: 540px;
    min-height: 540px;

    @media(max-width: 860px) { 
      width: 100%;
    }
`

const topRightBorderRadius = css`
    border-top-right-radius: 10px;
`;

const topLeftBorderRadius = css`
    border-top-left-radius: 10px;
`;

const bottomRightBorderRadius = css`
    border-bottom-right-radius: 10px;
`;

const bottomLeftBorderRadius = css`
    border-bottom-left-radius: 10px;
`;

const ImgContiner = styled.div<{
  img: string;
  topLeftRadius?: boolean;
  topRightRadius?: boolean;
  bottomRightRadius?: boolean;
  bottomLeftRadius?: boolean;
}>`
    background: linear-gradient(90deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0, 0.3) 100%),
    url(${({ img }) => (img) || ''}),
    #bdbdbd;
    background-size: cover;

    @media(max-width: 860px) {
      max-height: 300px;
    }
    ${ServicesContainers}
    ${({ topLeftRadius }): CSSProp => (topLeftRadius && topLeftBorderRadius) || ''}
    ${({ topRightRadius }): CSSProp => (topRightRadius && topRightBorderRadius) || ''}
    ${({ bottomRightRadius }): CSSProp => (bottomRightRadius && bottomRightBorderRadius) || ''}
    ${({ bottomLeftRadius }): CSSProp => (bottomLeftRadius && bottomLeftBorderRadius) || ''}
`;

const ArticleContainer = styled(Flex) <{
  topLeftRadius?: boolean;
  topRightRadius?: boolean;
  bottomRightRadius?: boolean;
  bottomLeftRadius?: boolean;
}>`
    background: #f5f5f5;
    padding: 25px;
    box-sizing: border-box;
    height: 100%;
    ${ServicesContainers}
    ${({ topLeftRadius }): CSSProp => (topLeftRadius && topLeftBorderRadius) || ''}
    ${({ topRightRadius }): CSSProp => (topRightRadius && topRightBorderRadius) || ''}
    ${({ bottomRightRadius }): CSSProp => (bottomRightRadius && bottomRightBorderRadius) || ''}
    ${({ bottomLeftRadius }): CSSProp => (bottomLeftRadius && bottomLeftBorderRadius) || ''}
`;

const ServicesPageFlex = styled(Flex)`
  ${MediumShadowStyle}
    margin: 0 auto;
    border-radius: 10px;
`;

const LineTopicSeparator = styled.div`
    height: 2px;
    width: 50%;
    background: #64B5F6;
`

const ServiceHeader = styled(H3)`
    margin: 0;
    font-size: 30px;
`;

const ServiceLinksHeader = styled(H4)`
  margin-bottom: 10px;
`;

const ServiceLink = styled(ButtonLink as any) <{ isLong: boolean }>`
  margin-right: 5px;
  margin-bottom: 5px;
  @media(max-width: ${media.small}){
    ${({ isLong }: { isLong: boolean }): CSSProp => (isLong && css`
      min-width: 100%;
    `) || ''}
  }
`;

const ButtonWrapper = styled(Flex)`
  flex-wrap: wrap;
`;

export const ServicesPage = (): JSX.Element => (
  <Container
    paddingVert
    paddingHori
    as='section'
  >
    <Flex>
      <HeaderWithLines>
        Czym się zajmujemy?
      </HeaderWithLines>
    </Flex>
    <Separator />
    <ServicesPageFlex block>
      {services.map((service: typeof services[0], index): React.ReactNode =>
        <TeaserBoxes key={service.header + service.subHeader} index={index}>
          <ImgContiner
            topRightRadius={index === 0}
            bottomRightRadius={(!(index % 2) && services.length - 1 === index)}
            img={service.img}
          />
          <ArticleContainer
            align="baseline"
            flexDirection="column"
            topLeftRadius={index === 0}
            bottomLeftRadius={(!(index % 2) && services.length - 1 === index)}
          >
            <ServiceHeader>
              {service.header}
            </ServiceHeader>
            <Paragraph>
              {service.subHeader}
            </Paragraph>
            <LineTopicSeparator />
            <br />
            <Paragraph>
              {service.article}
            </Paragraph>
            <ServiceLinksHeader>
              Zobacz:
            </ServiceLinksHeader>
            <ButtonWrapper justify="flex-start">
              {service.links.map((serviceLink) => (
                <ServiceLink isLong={serviceLink.text.length > 20} key={serviceLink.link + serviceLink.text} to={serviceLink.link}>
                  {serviceLink.text}
                </ServiceLink>
              ))}
            </ButtonWrapper>
          </ArticleContainer>
        </TeaserBoxes>
      )}
    </ServicesPageFlex>
  </Container>
)
