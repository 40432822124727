import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex } from './../Flex';

interface Props {
  children: JSX.Element[];
  index: number;
}

const TeaserBoxesWrapper = styled(Flex)`
    @media(max-width: 860px) {
        display: block;
    }
`;

export const TeaserBoxes = ({
  children,
  index = 0,
}: Props) => {
  const [isSmallView, setSmallView] = useState<boolean>(false);

  const onResizeWindow = () => {
    if (window.innerWidth <= 860) {
      setSmallView(true);
      window.removeEventListener('resize', onResizeWindow);
    }
  }

  useLayoutEffect((): void => {
    onResizeWindow();
    window.addEventListener('resize', onResizeWindow);
  }, []);

  return (
    <TeaserBoxesWrapper>
      {!isSmallView
        ? (
          <>
            {index % 2 ? children[0] : undefined}
            {children[1]}
            {!(index % 2) ? children[0] : undefined}
          </>
        )
        : (
          <>
            {children[0]}
            {children[1]}
          </>
        )}

    </TeaserBoxesWrapper>
  )
}