import React from 'react';
import styled, { css } from 'styled-components';
import { Section } from './Flex';
import { useInView } from "react-intersection-observer";
import { BottomFixedBarShadow } from './ShadowBox/ShadowBox';
import { media } from './media/media';

const ContactSectionWrapper = styled(Section) <{ inView: boolean }>`
    width: 100%;
    ${({ inView }) => (inView &&
    css`
      position: absolute;
      margin-top: -50px;
      `) ||
    css`
      position: fixed;
      z-index: 1;
      bottom: 0;
      background: #fff;
      ${BottomFixedBarShadow}
      @media(max-width: ${media.small}) {
        display: none;
      }
      `}

`;

const ContactIcon = styled.a<{ className?: string; inView: boolean; }>`
  ${({ inView }) => (inView &&
    css`
      background: #fff;
      color: #333;
      transition: color 1s;`)
    || css`
      background: transparent;
      color: #333;`}
      
    padding: 10px;
    font-size: 18px;
    border-radius: 100%;
    margin-right: 20px;
    text-decoration: none;

    @media(max-width: ${media.small}) {
        display: none;
    }
`;

const ContactP = styled.a<{ inView: boolean; }>`
    ${({ inView }) => (inView &&
    css`
        color: #fff;`)
    || css`
        color: #333;`}
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    font-family: 'Noto Sans', sans-serif;

    @media(max-width: ${media.small}) {
        &:hover {
          color: #58a5f0;
        }
    }
`;

const BoxWithText = styled.article<{ inView: boolean; }>`
    ${({ inView }) => (inView
    && css` height: 45px; `)
    || css` height: auto; `}
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    &:hover {
      ${ContactIcon} {
        color: #58a5f0;
      }
      ${({ inView }) => (!inView && css`
          ${ContactP} {
            color: #58a5f0;
          }`) || ''}
    }
`;

const ScrollWrapper = styled.div<{ inView: boolean }>``;

export const ContactSection = (): JSX.Element => {
  const [ref, inView] = useInView({
    threshold: 0
  });

  return (
    <ScrollWrapper inView={inView}>
      <div ref={ref}>
        <ContactSectionWrapper inView={inView}>
          <BoxWithText inView={inView}>
            <ContactIcon
              inView={inView}
              as='a'
              className="material-icons"
              href='tel:504107384'
            >
              phone
            </ContactIcon>
            <ContactP
              inView={inView}
              href='tel:504107384'
            >
              504-107-384
              </ContactP>
          </BoxWithText>
          <BoxWithText inView={inView}>
            <ContactIcon
              inView={inView}
              as='a'
              className="material-icons"
              href='mailTo:biuro@budmag.pl'
            >
              mail
            </ContactIcon>
            <ContactP
              inView={inView}
              href='mailTo:biuro@budmag.pl'
            >
              biuro@budmag.pl
            </ContactP>
          </BoxWithText>
          <BoxWithText inView={inView}>
            <ContactIcon
              inView={inView}
              as='a'
              className="material-icons"
              href="https://www.google.pl/maps/place/Mazowieckie/@52.2262999,18.9513493,7z/data=!3m1!4b1!4m5!3m4!1s0x471ed1800c8bd8f9:0x1029fb79438c080!8m2!3d51.8927182!4d21.0021679"
            >
              room
            </ContactIcon>
            <ContactP
              inView={inView}
              target="_blank"
              href="https://www.google.pl/maps/place/Mazowieckie/@52.2262999,18.9513493,7z/data=!3m1!4b1!4m5!3m4!1s0x471ed1800c8bd8f9:0x1029fb79438c080!8m2!3d51.8927182!4d21.0021679"
            >
              Mazowieckie
            </ContactP>
          </BoxWithText>
        </ContactSectionWrapper>
      </div>
    </ScrollWrapper>
  )
};