
import { css } from 'styled-components';

export const SmallShadowStyle = css`
  box-shadow: 0 2px 4px 0 rgba(66, 66, 66, 0.08), 0 1px 3px 1px rgba(66, 66, 66, 0.16);
`;

export const MediumShadowStyle = css`
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0,0,0,.12);
`;

export const LargeShadowStyle = css`
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0,0,0,.12);
`;

export const FooterShadow = css`
  box-shadow: 0 11px 15px -7px rgba(0,0,0,0.2), 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,.12);
`;

export const ButtonShadowStyle = css`
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0,0,0,.12);

  &:hover {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0,0,0,.12);
  }
`;

export const NavShadow = css`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
`;

export const BottomFixedBarShadow = css`
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.3);
`;